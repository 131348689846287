<template>
  <div class="teachers-lessons h-[80vh] overflow-hidden pb-10">
    <div class="teachers-lessons-header">
      <button
        v-for="btn in btns"
        :key="btn"
        :class="{
          active: day === btn,
        }"
        @click="(day = btn), getSchedule(), change_val()"
      >
        {{ $t("Academy." + btn) }}
      </button>
    </div>
    <div class="teachers-lessons-body h-full pb-10 hideScroll w-full">
      <table class="teachers-lessons-table h-full">
        <thead>
          <tr class="sticky top-0 left-0 z-10">
            <th class="border border-solid border-gray-200"></th>
            <th v-for="room in schedule" :key="room.room">
              {{ room.room }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="time in lessonTimes" :key="time">
            <td>{{ time }}</td>
            <template v-for="room in schedule" :key="room.room">
              <td>
                <TeacherLesson
                  v-if="
                    findLesson(room.room, time)?.lesson_end_time.slice(0, 5)
                  "
                  :group="findLesson(room.room, time)"
                />
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from "vue";
import { getTeachersSchedule } from "./api";
import TeacherLesson from "../../entities/Lesson/TeacherLesson.vue";
import store from "@/store";
import "./style/tutor-and-teacher-lessons.css";

const day = ref("odd");
const btns = ["odd", "even", "other"];
const schedule = ref([]);
const branch_id = ref(null);

const getSchedule = async () => {
  branch_id.value = store.state.branches.active_branch;
  if (branch_id.value)
    schedule.value = await getTeachersSchedule(branch_id.value, day.value).then(
      (res) => res.data
    );
};
onMounted(async () => {
  getSchedule();
});

watch(
  () => store.state.branches.active_branch,
  () => {
    if (store.state.branches.active_branch) getSchedule();
  }
);
const lessonTimes = ref([
  "09:00",
  "10:10",
  "10:30",
  "11:00",
  "14:00",
  "15:00",
  "15:10",
  "15:30",
  "16:00",
  "16:20",
  "17:00",
  "17:30",
  "18:30",
  "18:40",
]);

const findLesson = (room, time) => {
  return schedule.value
    .find((lesson) => lesson.room == room)
    .groups.find((group) => group.lesson_start_time.slice(0, 5) === time);
};
</script>

<style lang="scss">
.teachers-lessons-header button {
  color: #424242;
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>

import api from "@/apiConfig/api";
import { encryptText } from "@/utils/cryptFunc";
export default {
  namespaced: true,
  state() {
    return {
      token: localStorage.getItem("access_token"),
      branch: localStorage.getItem("branch_id"),
      role: "",
    };
  },
  mutations: {
    setToken(state, token) {
      state.token = token.access;
      localStorage.setItem("access_token", token.access);
      localStorage.setItem("refresh_token", token.refresh);
    },
    setRole(state, role) {
      state.role = role;
    },
    logout(state) {
      state.token = null;
      location.reload();
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("firstname");
      localStorage.removeItem("lastname");
      localStorage.removeItem("role");
      localStorage.removeItem("isManager");
      localStorage.removeItem("branch_id");
      localStorage.removeItem("group_id");
      localStorage.removeItem("group_details");
      localStorage.removeItem("group_name");
      localStorage.removeItem("admin_branches");
      localStorage.removeItem("isSuperAdmin");
      localStorage.removeItem("teacherActiveGrId");
      localStorage.removeItem("teacherMainTab");
    },
    CHANGEBRANCH(state, payload) {
      state.branch = payload;
    },
  },
  actions: {
    async login({ commit }, payload) {
      await api.post("/auth/signin", payload).then((res) => {
        const {
          is_teacher,
          department,
          role,
          is_branch_admin,
          first_name,
          last_name,
          id,
        } = res.data.user;
        localStorage.setItem("firstname", first_name);
        localStorage.setItem("lastname", last_name);
        commit("setToken", {
          access: res.data.access_token,
          refresh: res.data.refresh_token,
        });
        commit("setRole", role);
        if (
          localStorage.getItem("access_token") &&
          !is_teacher &&
          department === 5 &&
          role === 0
        ) {
          window.location.pathname = "/academy-dashboard";
          localStorage.setItem("role", encryptText("academy"));
          localStorage.setItem("isManager", "academy");
        } else if (
          localStorage.getItem("access_token") &&
          department === 1 &&
          role === 8
        ) {
          localStorage.setItem("role", encryptText("hunter"));
          window.location.pathname = "/hunter-dashboard";
        } else if (
          localStorage.getItem("access_token") &&
          !is_teacher &&
          department === 1
        ) {
          window.location.pathname = "/call-dashboard";
          localStorage.setItem("role", encryptText("call"));
        } else if (
          localStorage.getItem("access_token") &&
          !is_teacher &&
          department === 4 &&
          role === 0
        ) {
          window.location.pathname = "/audit-dashboard";
          localStorage.setItem("role", encryptText("audit"));
        } else if (localStorage.getItem("access_token") && is_teacher) {
          window.location.pathname = "/teacher-dashboard";
          localStorage.setItem("role", encryptText("teacher"));
          localStorage.setItem("userID", id);
        } else if (
          localStorage.getItem("access_token") &&
          !is_teacher &&
          department === 5 &&
          role === 2
        ) {
          window.location.pathname = "/instructor-dashboard";
          localStorage.setItem("role", encryptText("instructor"));
        } else if (
          localStorage.getItem("access_token") &&
          !is_teacher &&
          is_branch_admin
        ) {
          window.location.pathname = "/admin-dashboard";
          localStorage.setItem("role", encryptText("admin"));
          if (role === 0) {
            localStorage.setItem("isSuperAdmin", encryptText("superadmin"));
          }
        } else if (
          localStorage.getItem("access_token") &&
          department === 0 &&
          role === 3
        ) {
          localStorage.setItem("superuser", true);
        } else if (
          localStorage.getItem("access_token") &&
          department === 5 &&
          role === 5
        ) {
          localStorage.setItem("role", encryptText("examiner"));
          window.location.pathname = "/examiner-dashboard";
        } else if (
          localStorage.getItem("access_token") &&
          department === 6 &&
          role === 0
        ) {
          localStorage.setItem("role", encryptText("finance"));
          window.location.pathname = "/finance-dashboard";
        } else if (
          localStorage.getItem("access_token") &&
          department === 5 &&
          role === 6
        ) {
          localStorage.setItem("role", encryptText("kurator"));
          window.location.pathname = "/kurator-dashboard";
        } else if (localStorage.getItem("access_token") && department === 3) {
          localStorage.setItem("role", encryptText("marketing"));
          window.location.pathname = "/marketing-dashboard";
        } else if (
          localStorage.getItem("access_token") &&
          department === 6 &&
          role === 7
        ) {
          localStorage.setItem("role", encryptText("axo"));
          window.location.pathname = "/axo-dashboard";
        } else if (
          localStorage.getItem("access_token") &&
          department === 5 &&
          role === 9
        ) {
          localStorage.setItem("role", encryptText("tutor"));
          window.location.pathname = "/tutor-dashboard";
          localStorage.setItem("userID", id);
        } else if (
          localStorage.getItem("access_token") &&
          department === 4 &&
          role === 12
        ) {
          localStorage.setItem("role", encryptText("moderator"));
          window.location.pathname = "/moderator-dashboard";
        } else if (
          localStorage.getItem("access_token") &&
          department === 5 &&
          role === 11
        ) {
          localStorage.setItem("role", encryptText("branchacademy"));
          window.location.pathname = "/branchacademy-dashboard";
        }
      });
    },
    changeBranchAction({ commit }, payload) {
      commit("CHANGEBRANCH", payload);
    },
    async refreshAccessToken({ commit }) {
      await api
        .get("/auth/token")
        .then((res) => {
          commit("setToken", {
            access: res.data,
          });
        })
        .catch(() => {
          commit("logout");
        });
    },
  },
  getters: {
    token(state) {
      return state.token;
    },
    isAuth(_, getters) {
      return !!getters.token;
    },
  },
};

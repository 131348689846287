<template>
  <div class="sidebar__link-wrapper">
    <router-link to="/academy-teacher-list" class="sidebar__link">
      <i class="fa-solid fa-user-tie"></i>
      <p>{{ $t("Sidebar.mentors-tutors") }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/teacher-statistics" class="sidebar__link">
      <i class="fa-solid fa-chart-line"></i>
      <p>{{ $t("Sidebar.teachers-stats") }}</p>
    </router-link>
  </div>
  <!-- <div class="sidebar__link-wrapper">
    <router-link to="/teacher-audit" class="sidebar__link">
      <i class="fa-solid fa-user-check"></i>
      <p>{{ $t("Sidebar.qa-audit") }}</p>
    </router-link>
  </div> -->
  <!-- <div class="sidebar__link-wrapper">
    <router-link to="/teacher-audit-statistics" class="sidebar__link">
      <i class="fa-solid fa-chart-line"></i>
      <p>{{ $t("Sidebar.academy-statistics") }}</p>
    </router-link>
  </div> -->
  <div class="sidebar__link-wrapper">
    <router-link to="/teachers-grading" class="sidebar__link">
      <i class="fa-solid fa-user-pen"></i>
      <p>Grading</p>
    </router-link>
  </div>

  <div class="sidebar__link-wrapper">
    <router-link to="/teachers-lessons-schedule" class="sidebar__link">
      <i class="fa-solid fa-calendar-days"></i>
      <p>{{ $t("Sidebar.schedule") }}</p>
    </router-link>
  </div>
  <!-- <div class="sidebar__link-wrapper">
    <router-link to="/generate-certificate" class="sidebar__link">
      <i class="fa-solid fa-certificate"></i>
      <p>{{ $t("Sidebar.certificate") }}</p>
    </router-link>
  </div> -->
  <div class="sidebar__link-wrapper">
    <router-link to="/students-results" class="sidebar__link">
      <i class="fa-solid fa-user-graduate"></i>
      <p>Student results</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/branchacademy-dashboard" class="sidebar__link">
      <i class="fa-solid fa-building"></i>
      <p>Branch Academy</p>
    </router-link>
  </div>
</template>
